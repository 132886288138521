body {
    margin: 0;
    font-size: 16px;
    font-family: 'Inter', sans-serif !important;
}

.container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    box-sizing: border-box;
    padding: 0 20px;
}

@media (max-width: 575px) {
    .hero-banner .attention-wrapper {
        max-width: 100% !important;
        margin-bottom: 20px;
    }

    .request-inq {
        padding: 40px !important;
    }

    .request-inq .content-wrapper {
        margin-bottom: 20px;
        border-right: none !important;
        border-bottom: 1px solid rgba(255, 255, 255, 0.10);
        ;
    }

    .lower-header .ant-col {
        text-align: center !important;
        margin-bottom: 10px;
    }

    .lower-header .actions a {
        margin-left: 16px !important;
    }

    .footer-details .ant-col {
        margin-bottom: 30px;
    }
}

@media (min-width: 576px) and (max-width: 768px) {
    .container {
        width: 576px;
    }

    .hero-banner .attention-wrapper {
        max-width: 100% !important;
        margin-bottom: 20px;
    }

    .request-inq .content-wrapper {
        margin-bottom: 20px;
        border-right: none !important;
        border-bottom: 1px solid rgba(255, 255, 255, 0.10);
        ;
    }

    .lower-header .ant-col {
        text-align: center !important;
        margin-bottom: 10px;
    }

    .lower-header .actions a {
        margin-left: 20px !important;
    }

    .footer-details .ant-col {
        margin-bottom: 30px;
    }

}

@media (min-width: 768px) and (max-width: 991px) {
    .container {
        width: 768px;
    }

    .lower-header .actions a {
        margin-left: 16px !important;
    }

    .footer-details .ant-col {
        margin-bottom: 30px;
    }
}

@media (min-width: 992px) {
    .container {
        width: 992px;
    }
}

@media (min-width: 1200px) {
    .container {
        width: 1200px;
    }
}

.top-header .actions {
    text-align: right;
}

.top-header svg {
    margin: 0 12px;
    font-size: 24px;
    color: #fff
}

.hero-banner {
    background-size: cover;
    padding-bottom: 70px;
}

.hero-banner .container {
    padding: 40px 20px;
}

.hero-banner .hero-left {}

.hero-banner .banner-title {
    margin: 0;
    color: #FFF;

    font-size: 56px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    max-width: 580px;
}

.hero-banner .banner-title.sub-title {
    font-size: 36px;
    text-align: center;
}

.hero-banner .attention-wrapper {
    position: relative;

    margin-top: 50px;
    padding: 32px;

    max-width: 430px;
    flex-shrink: 0;

    border-left: 3px solid #E32929;
    border-radius: 10px;

    background: linear-gradient(90deg, rgba(0, 0, 0, 0.40) 43.47%, rgba(0, 0, 0, 0.00) 100%);
}

.hero-banner .attention-wrapper::after {
    position: absolute;
    top: 0;
    left: -3px;
    width: 100%;
    height: calc(100% - 5px);
    content: '';

    border-style: solid;
    border-image: linear-gradient(to right, transparent, #E32929 12px, transparent) 1;
    border-image-slice: 1;
}

.hero-banner .attention-wrapper .title {
    color: #E32929;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
}

.hero-banner .attention-wrapper p {
    margin-top: 16px;
    color: #FFF;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* 150% */
}

.tracking-form {
    padding: 60px;
    border-radius: 20px;
    background: #FFF;
}

.tracking-form .title {
    display: block;
    color: #000;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 32px;
}

.how-it-works {
    padding: 80px 0;
}

.how-it-works .banner-title {
    margin: 0;
    color: #000;
    text-align: center;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.how-it-works .moto {
    margin: auto;
    margin-top: 24px;

    color: rgba(0, 0, 0, 0.50);
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 27px;

    max-width: 784px;
}

.how-it-works .banner-img {
    margin-top: 40px;
    width: 100%;
}

.request-inq {
    padding: 80px 80px;
    margin-bottom: 80px;
    border-radius: 40px;
    background: #282E2C;
}

.request-inq .content-wrapper {
    border-right: 1px solid rgba(255, 255, 255, 0.10);
}

.request-inq .content {
    max-width: 500px;

    color: #FFF;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 27px;
    /* 150% */
    text-align: left;

    opacity: 0.5;
}

.request-inq .title {
    margin: 0;
    margin-bottom: 32px;

    color: #FFF;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.request-inq .ant-form-item-label>label {
    color: rgba(255, 255, 255, 0.50) !important;
}

.lower-header {
    padding: 24px 20px;
    background: #FFF;
    box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.10) inset;
}

.lower-header .actions {
    text-align: right;
}

.lower-header .actions a {
    text-decoration: none;
    display: inline-block;

    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    margin-left: 58px;
}

.footer-details {
    padding: 40px 0;
    background: #F6F6F6;
}

.footer-details .header-wrapper {
    display: flex;
    align-items: self-end;

    color: #3081DF;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
}

.footer-details .header-wrapper svg {
    margin-right: 10px;
    font-size: 15px;
}

.footer-details .content-wrapper {
    margin-top: 18px;
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
}

.footer-details .content-wrapper p {
    margin-top: 5px;
    margin-bottom: 0 !important;

}

.footer-summary {
    padding: 50px 0;
}

.footer-summary p {
    margin: 0;
    color: #949292;
}

.footer-summary svg {
    margin: 0 12px;
    font-size: 24px;
    color: #949292
}

.footer-summary input {
    width: calc(100% - 155px);
}

.footer-summary button {
    float: right;
}

.ant-btn {
    border-radius: 4px;
    padding-left: 42px;
    padding-right: 42px;
}

.ant-input {
    border-radius: 4px;
}

.ant-form-item-required::before {
    display: none !important;
}

.ant-input::placeholder {
    color: #000;
    opacity: 0.5
}

.ant-form-item {
    margin-bottom: 24px !important;
}

.ant-form-item-label {
    padding-bottom: 16px !important;
}

.ant-form-item-label>label {
    margin: 0 !important;
    color: rgba(0, 0, 0, 0.50) !important;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
}

.warnings {
    min-height: calc(100vh - 526px);
}

.warnings article {
    margin-bottom: 40px;
}

.warnings .warnings-list {
    padding-left: 0;
    list-style: none;
    margin-top: 30px;
}

.warnings .warnings-list li {
    border-bottom: 1px solid #e6e5e5;
    padding-bottom: 10px;
}

.warnings .warnings-list p {
    color: #bdbdbd;
    margin-bottom: 5px;
}

.warnings .warnings-list a {
    text-transform: uppercase;
}

.warnings .warnings-list a:hover {
    text-decoration: underline;
    cursor: pointer;
    color: #29a37d;
}

.warning-title {
    text-transform: uppercase;
}

.warning-sub-title {
    color: #bdbdbd;
    margin-bottom: 30px;
}

.signature-wrapper {
    margin: 30px 0;
}

.signature-wrapper p {
    margin: 0;
}

.supplier-list {
    margin: 30px 0;
}

.supplier-list .highlight-red {
    color: red;
    margin-bottom: 0;
    margin-top: 5px;
}

.supplier-list .highlight-red span {
    width: 80px;
    display: inline-block;
}

.supplier-list h3 {
    margin-bottom: 10px;
    color: red;
}

.supplier-list h3 span {
    font-size: 16px;
    font-weight: normal;
}

.warning-imgs-wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin: 30px 0;
}

.warning-imgs-wrapper img {
    max-width: 800px;
}

.new-year-notice-wrapper {
    width: 100%;
}

.new-year-notice-wrapper img {
    width: 100%;
}

.new-year-notice-modal .ant-modal-title {
    color: transparent;
}


/* About Us Styles */

.about-us,
.privacy {
    margin-top: 30px;
}

.about-us p,
.privacy p {
    font-size: 16px;
}

.about-us .goals-list {
    padding-left: 20px;
}

.about-us .goals-list li {
    font-size: 16px;
    line-height: 1.75rem;
}

.about-us h2,
.privacy h2 {
    margin: 0;
    color: #000;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.download-app-note {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.download-app-note img {
    width: 100%;
    border-radius: 10px;
}

.download-app-note .buttons-block {
    flex-direction: column;
    gap: 10px;
    margin-top: 10px;
    margin-bottom: 30px; 
}

.download-app-note .download-guide-btn {
    border-radius: 10px;
    box-shadow: none;
    background: #ffac00;
}

.download-app-note .download-app-btn {
    border-radius: 10px;
    box-shadow: none;
}

.download-app-note .download-guide-btn:hover {
    background: #dfa000 !important;
}

.download-app-note .download-guide-btn:active {
    background: #dfa000 !important;
}